import React, {useEffect, useState, useRef} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { Grid, TextField, Button } from "@mui/material";

import * as loginService from "../../services/login-service";
import { getUserThunk } from "../../services/user-thunk";
import { GOOGLE_RECAPTCHA_SITE_KEY } from "../../constants";

import "./login.css";

const Signin = ({ setIsAuthenticated }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);

  const [loginError, setLoginError] = useState(false);
  const [loginInput, setLoginInput] = useState({username:'', password:''});
  const [loginLoading, setLoginLoading] = useState(false);
  const [isCaptchaValidated, setIsCaptchaValidated] = useState(false);
  const [captchaValue, setCaptcha] = useState('');
  const [isLoginEnabled, setIsLoginEnabled] = useState(false);

  useEffect(()=> {
    checkLoginEnable() ? setIsLoginEnabled(true) : setIsLoginEnabled(false);
  },[loginInput, isCaptchaValidated])

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginLoading(true);
    loginInput['g-recaptcha-response'] = captchaValue
    loginService
      .login(loginInput)
      .then((response) => {
        setLoginError(false);
        const { access, userId } = response.data;
        const redirectPath = localStorage.getItem('redirectPath') || '/';
        localStorage.setItem("accessToken", access);
        dispatch(getUserThunk());
        setIsAuthenticated(true);
        setLoginLoading(false);
        navigate(redirectPath);
        try {
          if (window.umami) {
            window.umami.track('userLogin', {
              "userId": userId,
              "loginTimestamp": new Date().toISOString()
            });
          } else {
            console.log("window.umami not there");
          }
        } catch (error) {
          console.error("An error occurred:", error);
        }
        // localStorage.removeItem('redirectPath');
      })
      .catch((error) => {
        if (error.response.data?.detail === "User Not Verified") {
          alert("Please Complete Verfication")
          const encodedEmail = encodeURIComponent(loginInput['username']);
          console.log(encodedEmail)
          navigate(`/user-verification/${encodedEmail}`);
          return;
        }
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setLoginError(error.response.data || 'Unknown error occurred');
        setIsLoginEnabled(false);
      });
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setLoginInput({
      ...loginInput,
      [name]: newValue,
    });
    setLoginError(false);
  };

  const checkLoginEnable = () => {
    if(isCaptchaValidated && loginInput.password!='' && loginInput.username!='')
    {
      return true;
    }
    return false;
  };

  const setResetPassword = (event) => {
    navigate("/forget-password");
  }

  const onChangeCaptcha = (value) => {
    setCaptcha(value);
    setIsCaptchaValidated(true);
  }

  return (
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>
          
          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="username" style={{marginBottom: '5px'}}>Email</label>
            <TextField
              id="username"
              fullWidth
              required
              aria-label="username text box"
              name="username"
              type="text"
              onChange={handleInput}
            />
          </Grid>

          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="password" style={{marginBottom: '5px'}}>Password</label>
            <TextField
              id="password"
              required
              fullWidth
              aria-label="password text box"
              name="password"
              type="password"
              onChange={handleInput}
            />
          </Grid>

          <Grid item xs={6} className="wd-login-item" style={{ paddingTop: '5px' }}>
            <div style={{ textAlign: 'right', marginRight: '5px', color: '#ED1846', cursor: "pointer" }} >
              <span onClick={setResetPassword}>  Forgot password? </span>
            </div>
          </Grid>

          <Grid item xs={6} className="wd-login-item">
            {
              <div style={{ display: 'flex'}}>
                <ReCAPTCHA ref={recaptchaRef} sitekey={GOOGLE_RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
              </div>
            }
          </Grid>

          {
            loginError && (
              <p style={{color: "red", marginTop: "15px"}}>{loginError.detail}</p>
            )
          }

        <Grid item xs={12} className="login-full-width">
          <Button
            className="wd-submit-btn-login"
            variant="contained"
            aria-label="submit button"
            onClick={handleSubmit}
            disabled={!isLoginEnabled}
            style={{ marginBottom: "10px", fontSize: "medium" }}
          >
            Sign in
          </Button>
          <span>
            By signing in, you agree to our{' '}
            <a href="https://info.supplytrace.org/terms-of-use/" target="_blank"
              rel="noopener noreferrer">Terms of Use </a>and
            <a href="https://info.supplytrace.org/privacy-policy/ " target="_blank"
              rel="noopener noreferrer"> Privacy Policy
            </a>
          </span>
        </Grid>

        </Grid>
      </form>
  );
};

export default Signin;