import { useState, useEffect } from "react";
import { axiosRequest } from "../../../services/utils/axios";
import { API_BASE } from "../../../constants";
import EvidencesTableComponent from "./EvidencesComponent";
const EvidenceComponent = ({company,companyName}) => {
  const [evidences, setEvidences] = useState([]);
  const [source_investigation, setSourceInvestigation] = useState(false);

    const loadEvidences = async () => {
        try {
            const evidenceInfo = await axiosRequest.get(`${API_BASE}/getEvidences/?search=${company}`)
            console.log(evidenceInfo)
            setEvidences(evidenceInfo?.data?.evidences)
            setSourceInvestigation(evidenceInfo?.data?.source_investigation);

          } catch (error) {
            console.log(error)
          }
    }

    useEffect(() => {
        loadEvidences()
    }, [company])
    return (
        <>
          {evidences?.length>0 && (
            <EvidencesTableComponent evidences={evidences} company={company} companyName={companyName}/>
          )
          }
          <div style={{margin: '0px 1rem 1rem 1rem'}}>
          {source_investigation && (
                    <div className="risk-indicator-container ">
                        <p>The potential risk indicator for this entity has been added by the Sheffield Hallam University Forced Labor Lab</p>
                    </div>
          )}
          </div>
          
        </>
    )

}

export default EvidenceComponent;