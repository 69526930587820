import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import { Grid, TextField, Button } from "@mui/material";

import { API_BASE } from "../../constants";

import "./login.css";


const ForgotPassword = () => {
 
  const navigate = useNavigate();

  const [loginInput, setLoginInput] = useState({});
  const [emailError, setEmailError] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  const validateEmail = (email) => {
    const regex = /\S+@\S+\.\S+/; 
    return regex.test(email);
  };

  const sendResetPasswordEmail = async (emailData) => {
    try {
      const response = await axios.post(`${API_BASE}/reset-password/`, {"userId": emailData.email});
      
      if (response.status === 200) {
        setEmailError("")
        console.log('Success:', response.data.msg); 
        navigate('/login');
        return "success"
      }
    } catch (error) {
      setEmailError(error.response?.data?.message || "Unexpected error occured")
      if (error.response) {
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        if (error.response.status === 403) {
          setEmailError('Invalid credentials');
        }
      } else if (error.request) {
        console.error('Error request:', error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validEmail) {
        const emailData = {
            email: loginInput.email
        };
        sendResetPasswordEmail(emailData).then((response) => {
            // changeTab('signin');
        });
    }
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;

    if (name === 'email') {
      if (!validateEmail(newValue)) {
        setEmailError('Please enter a valid email address.');
        setValidEmail(false);
      } else {
        setEmailError('');
        setValidEmail(true);
      }
    }

    setLoginInput({
      ...loginInput,
      [name]: newValue,
    });
  };

  return (
    <div className="login-window">
      
      <Link to="/" className="unstyled-link">
        <div className="login-header" >
          <img
            className="login-page-logo"
            src="logo/ST_OneLine_RGB.png"
            alt="our logo"

          ></img>
          <h3 className="login-page-logo-tagline">Open supply chain intelligence for a just world</h3>
        </div>
      </Link>
      
      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>

          <Grid item xs={6} className="wd-login-item">
            <label htmlFor="forgot_password_email" style={{ margin: 0 }}>Email</label>
            <TextField
              id="forgot_password_email"
              fullWidth
              required
              aria-label="email text box"
              name="email"
              type="email"
              // label="Email"
              onChange={handleInput}
            />
          </Grid>

          {emailError !== '' && 
          <Grid item xs={12} className="wd-login-item" style={{ margin: '10px 0', color: 'red' }}>
            <span style={{ fontSize: '1.1rem' }}>
              {emailError}
            </span>
          </Grid>}
         
          <Grid item xs={12} className="login-full-width">
            <Button
              className="wd-submit-btn-login"
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
             disabled= {!validEmail}
            >
              Send Email
            </Button>
          </Grid>

        </Grid>
      </form>

    </div>
  );
};

export default ForgotPassword;
