import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Box,
  Button,
  Popover,
  TextField,
  MenuItem
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
const ScrollableCell = ({ value }) => (
  <Box
    sx={{
      height: '100%',
      width: '100%',
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'scroll',
        scrollbarWidth: 'none', // Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // Safari and Chrome
        },
        msOverflowStyle: 'none', // IE and Edge
      }}
    >
      <Typography 
        variant="body2" 
        sx={{ 
          padding: '16px 8px',
          paddingRight: '25px', // Compensate for hidden scrollbar
        }}
      >
        {value}
      </Typography>
    </Box>
  </Box>
);

const LocationsTableComponent = ({locations, filterModel, onFilterModelChange,setSelectedLocation }) => {
  const [anchorEl, setAnchorEl] = useState(null);
 
  const [tempFilter, setTempFilter] = useState({
    field: '',
    operator: '',
    value: '',
  });

  const valueInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isFocused && valueInputRef.current) {
      valueInputRef.current.focus();
    }
  }, [tempFilter, isFocused]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempFilter({
      field: filterModel.items[0]?.field || '',
      operator: filterModel.items[0]?.operator || '',
      value: filterModel.items[0]?.value || '',
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const columns = [
    { 
      field: 'company_name_english', 
      headerName: 'Location', 
      flex: 0.3,
      headerClassName: 'super-app-theme--header'
    },
    { 
      field: 'full_address', 
      headerName: 'Address', 
      flex: 0.7,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => <ScrollableCell value={params.value} />,

    },
  ];

  const rows = locations.map((location, index) => ({
    id: index,
    ...location
  }));


  const getRowClassName = (params) => {
    return params.row.suspicious ? 'suspicious-row' : 'normal-row';
  };
  return (
    <Box sx={{ width: '100%', height: 'auto', display: 'flex', flexDirection: 'column' }}>
      
      <Box sx={{ flexGrow: 1 }}>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          disableSelectionOnClick
          disableRowSelectionOnClick
          checkboxSelection={false}
          getRowClassName={getRowClassName}
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          onCellClick={(params, event) => {
            if (params.field === 'company_name_english') {
              if(setSelectedLocation) {
                setSelectedLocation(params.row.id);
              }
            }
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5, page: 0 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{
            '& .super-app-theme--header': {
              // backgroundColor: 'white',
              fontWeight: 'bold',
              fontSize: 16,
            },
            '& .MuiDataGrid-columnHeaders': {
              border: '1px solid black',
            },
            '& .MuiDataGrid-columnHeader': {
              borderRight: '1px solid black',
            },
           
            '& .MuiDataGrid-cell': {
              borderRight: '1px solid black',
              borderBottom: '1px solid black',
            },
            '& .MuiDataGrid-cell:first-of-type': {
              borderLeft: '1px solid black',
            },

            '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: '1px solid black',
            },
            '& .suspicious-row': {
              backgroundColor: '#ffcccb', // Light red for suspicious rows
              '&:hover': {
                backgroundColor: '#ffb3b3', // Slightly darker red on hover
              },
            },
            '& .normal-row': {
              backgroundColor: '#e6ffe6', // Light green for normal rows
              '&:hover': {
                backgroundColor: '#ccffcc', // Slightly darker green on hover
              },
            },
          }}
        />
      </Box>
        <Typography 
        color="primary"
        sx={{
          mt: 2,
          p: 1,
          borderTop: '1px solid #ccc',
          borderBottom: '1px solid #ccc',
          textAlign: 'center',
          fontStyle: 'italic',
          fontSize: '1.5rem',
          background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
        }}
      >
        Click on any location to know more about its relationships over years
      </Typography>
    </Box>
  );
};

export default LocationsTableComponent;