import React, { useState, useRef, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Button,
  Popover,
  TextField,
  MenuItem,
  Tooltip,
  IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSelector } from 'react-redux';
import { InfoIcon } from 'lucide-react';
import { SHU_INFO } from '../../../constants';
import './index.css';
import { axiosRequest } from '../../../services/utils/axios';
import { API_BASE } from '../../../constants';
const EvidencesTableComponent = ({evidences, companyName, company }) => {
  const { currentUser } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterModel, setFilterModel] = useState({
    items: [],
    quickFilterValues: [],
  });
  const [tempFilter, setTempFilter] = useState({
    field: '',
    operator: '',
    value: '',
  });

  const valueInputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    if (isFocused && valueInputRef.current) {
      valueInputRef.current.focus();
    }
  }, [tempFilter, isFocused]);

  const sourceClicked = () => {
    try {
      let organisationType = currentUser?.organisation_type ?? "unknown";
      organisationType = organisationType.split(' ')[0];
      if (window.umami) {
        window.umami.track('EvidenceClicked ' + organisationType, {
          "company": companyName + " (" + company + ")",
        });
      } else {
        console.log("window.umami not there");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setTempFilter({
      field: filterModel.items[0]?.field || '',
      operator: filterModel.items[0]?.operator || '',
      value: filterModel.items[0]?.value || '',
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsFocused(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const columns = [
    { 
      field: 'transaction_date', 
      headerName: 'Transaction date', 
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    { 
      field: 'media_reported_date', 
      headerName: 'Observed date', 
      flex: 1,
      headerClassName: 'super-app-theme--header'
    },
    { 
      field: 'source', 
      headerName: 'Source', 
      flex: 3,
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => (
              <div
                dangerouslySetInnerHTML={{__html: params.value}}
                onClick={sourceClicked}
              />
      ),
    },
  ];

  const rows = evidences.map((evidence, index) => ({
    id: index,
    ...evidence
  }));

  const handleTempFilterChange = (field, value) => {
    setTempFilter(prev => ({ ...prev, [field]: value }));
    if (field === 'value') {
      setIsFocused(true);
    }
  };

  const handleApplyFilter = () => {
    if (tempFilter.field && tempFilter.operator && tempFilter.value) {
      setFilterModel({
        ...filterModel,
        items: [tempFilter],
      });
    } else {
      setFilterModel({
        ...filterModel,
        items: [],
      });
    }
    handleClose();
  };

  const CustomFilterComponent = () => (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
        select
        label="Field"
        value={tempFilter.field}
        onChange={(e) => handleTempFilterChange('field', e.target.value)}
      >
        {columns.map((column) => (
          <MenuItem key={column.field} value={column.field}>
            {column.headerName}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Operator"
        value={tempFilter.operator}
        onChange={(e) => handleTempFilterChange('operator', e.target.value)}
      >
        <MenuItem value="contains">Contains</MenuItem>
        <MenuItem value="equals">Equals</MenuItem>
        <MenuItem value="startsWith">Starts with</MenuItem>
        <MenuItem value="endsWith">Ends with</MenuItem>
      </TextField>
      <TextField
        label="Value"
        value={tempFilter.value}
        onChange={(e) => handleTempFilterChange('value', e.target.value)}
        inputRef={valueInputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleApplyFilter}
      >
        Apply Filter
      </Button>
    </Box>
  );

  return (
    <>
      {evidences.length>0 && (
        <div className='evidences-container'>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" color="primary">
                        Evidence
                    </Typography>
                    <Box>
                        <Button 
                            onClick={handleClick}
                            startIcon={<FilterListIcon />} 
                            sx={{ mr: 1, color: 'black', border: '1px solid black' }}
                        >
                            Filter
                        </Button>
                    
                        <Tooltip title={SHU_INFO} arrow>
                        <IconButton size="large">
                            <InfoIcon color="black" />
                        </IconButton>
                    </Tooltip>
                    </Box>
                    
                </Box>
                <p><strong>{SHU_INFO}</strong></p>
                <Box>
                    <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowHeight={() => 'auto'} 
                    autoHeight
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    checkboxSelection={false}
                    filterModel={filterModel}
                    onFilterModelChange={(newModel) => setFilterModel(newModel)}
                    initialState={{
                        pagination: {
                        paginationModel: { pageSize: 5, page: 0 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 25]}
                    sx={{
                        '& .super-app-theme--header': {
                        backgroundColor: 'white',
                        fontWeight: 'bold',
                        fontSize: 16,
                        },
                        '& .super-app-theme--header': {
                        // backgroundColor: 'white',
                        fontWeight: 'bold',
                        fontSize: 16,
                        },
                        '& .MuiDataGrid-columnHeaders': {
                        border: '1px solid black',
                        },
                        '& .MuiDataGrid-columnHeader': {
                        borderRight: '1px solid black',
                        },
                    
                        '& .MuiDataGrid-cell': {
                        borderRight: '1px solid black',
                        borderBottom: '1px solid black',
                        },
                        '& .MuiDataGrid-cell:first-of-type': {
                        borderLeft: '1px solid black',
                        },

                        '& .MuiDataGrid-row:last-child .MuiDataGrid-cell': {
                        borderBottom: 'none',
                        },
                        '& .MuiDataGrid-footerContainer': {
                        borderTop: '1px solid black',
                        },
                    }}
                    />
                </Box>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                >
                    <CustomFilterComponent />
                </Popover>
            </Box>
          </div>
      )}
    </>
    
    
  );
};

export default EvidencesTableComponent;