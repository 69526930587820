import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import NavbarComponent from './NavbarComponent';
import HomeScreen from './HomeScreen';
import AdvancedSearchScreen from "./AdvancedSearchScreen";
import AboutScreen from "./AboutScreen";
import ContactScreen from "./ContactScreen";
import Footer from './Footer';
import CompanyScreen from './CompanyScreen';
import InvestigateCompany from './InvestigateCompanyScreen';
import LoginPage from './Login/LoginPage';
import VerifyUser from './Login/VerifyUser';
import Signin from './Login/Signin';
import ForgotPassword from './Login/ForgotPassword';
import ResetPassword from './Login/ResetPassword';
import Register from './Login/Register';
import MergeScreen from "./MergeScreen";
import MergeRequestScreen from './MergeRequestScreen';

import { getUserThunk } from "../services/user-thunk";

import './index.css';

const DatahubApplication = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const { currentUser, loading: userLoading } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (!userLoading) {
      if (currentUser && ('username' in currentUser)) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    }
  }, [currentUser, userLoading]);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log("user fetched again");
    dispatch(getUserThunk());
  }, []);

  return (
    <BrowserRouter>
      <div className="app-container">
       
        <main role="main">
          <div className="main-container">
            <Routes>

              <Route
                path="/login"
                // element={ <Signin setIsAuthenticated={setIsAuthenticated} />}
                element={<LoginPage setIsAuthenticated={setIsAuthenticated} />} 
              />

              <Route
                path="/reset-password"
                element={<ResetPassword/>}
              />

              <Route
                path="/forget-password"
                element={<ForgotPassword />}
              />

              <Route
                path="/register"
                // element={<Register />}
                element={<LoginPage setIsAuthenticated={setIsAuthenticated} />}
              />

              <Route
                path="/user-verification"
                element={<VerifyUser />}
              />

               <Route
                path="/user-verification/:email"
                element={<VerifyUser />}
              />

              <Route
                path="/about"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <AboutScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/contact"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <ContactScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/investigate-company"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <InvestigateCompany />
                  </PrivateRoute>
                }
              />

              <Route
                path="/company/:companyId"
                element={
                  // <PrivateRoute isAuthenticated={isAuthenticated}>
                  <>
                    <NavbarComponent />
                    <CompanyScreen />
                  </>
                  // </PrivateRoute>
                }
              />

              <Route
                path="/advanced-trace"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <AdvancedSearchScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/advanced-trace/:query"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <AdvancedSearchScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/merge/:mergeId"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <NavbarComponent />
                    <MergeScreen />
                  </PrivateRoute>
                }
              />

              <Route
                path="/merge"
                element={
                  // <PrivateRoute isAuthenticated={isAuthenticated}>
                  <>
                    <NavbarComponent />
                    <MergeScreen />
                  </>
                  // </PrivateRoute>
                }
              />

              <Route
                path="/mergerequest"
                element={
                  //<PrivateRoute isAuthenticated={isAuthenticated}>
                  <>
                    <NavbarComponent />
                    <MergeRequestScreen />
                  </>
                  //</PrivateRoute>
                }
              /> 

              <Route
                path="/"
                element={
                  // <PrivateRoute isAuthenticated={isAuthenticated}>
                  <>
                    <NavbarComponent />
                    <HomeScreen />
                    <Footer />
                  </>
                  // </PrivateRoute>
                }
              />

              {/* <Route path="*"
                            element={
                                <PrivateRoute isAuthenticated={isAuthenticated}>
                                    <HomeScreen />
                                </PrivateRoute>
                            }
                        /> */}

              <Route path="*" element={<Navigate to="/" />} />
              {/* 
                        <Route path="/" element={isAuthenticated ? <HomeScreen /> : <Navigate to="/login" />} />
                        <Route path="/investigate-company" element={isAuthenticated ? <InvestigateCompany /> : <Navigate to="/login" />} />
                        <Route path="/company/:companyName" element={isAuthenticated ? <CompanyScreen /> : <Navigate to="/login" />} /> */}
            
            </Routes>
          </div>
        </main>
        
      </div>
    </BrowserRouter>
  );
};

export default DatahubApplication;