/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import Skeleton from "@mui/material/Skeleton";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import "./index.css";
import DashboardSummaryComponent from "../Summary/DashboardSummaryComponent";
import { max, min } from "lodash";
import CompanyLocations from "./CompanyLocations/CompanyLocations";
import { axiosRequest } from "../../../services/utils/axios";
import { API_BASE } from "../../../constants";
import RedactLoginComponent from "../../RedactLogin/RedactLoginComponent";
import EvidencesTableComponent from "./EvidencesComponent";
import TopSupplierCustomerChart from "./Charts/TopCharts";
import ProductCharts from "./Charts/ProductsCharts";
import CountryCharts from "./Charts/CountryCharts";
import YearBreakdownChart from "./Charts/YearBreakdownChart";


const DashboardComponent = ({ company, companyName , setCompanyAddress }) => {
  const { currentUser } = useSelector((state) => state.user);    
  const [company_info, setCompanyInfo ] = useState({})
  const [loading, setLoading] = useState(false);
  const [showLoginComponent, setShowLoginComponent] = useState(false);
  
  useEffect(() => {
      fetchLocations()
      fetch_company_info()
  }, []);

  useEffect(() => {
    if(currentUser && ('username' in currentUser)) {
      setShowLoginComponent(false)
    } else {
      setShowLoginComponent(true)
    }
  }, [currentUser]);
  const [shipmentCount, setShipmentCount] = useState(0);
  const [minYear, setMinYear] = useState(2014);
  const [maxYear, setMaxYear] = useState(2024);
  const [source_investigation, setSourceInvestigation] = useState(false);

  useEffect(() => {
    var shipments = 0
    var minyear = 2024
    var maxyear = 2014
    console.log(company_info)
    if (company_info?.data) {
      const yearData = company_info?.data?.year_data
      yearData.forEach((data) => {
        shipments+=(data.received_total_transactions + data.supplied_total_transactions);
        minyear = min(minyear, data.year)
        maxyear = max(maxyear, data.year)
      })
    }
    setMinYear(minYear);
    setMaxYear(maxYear);
    setShipmentCount(shipments);
    if(company_info?.data?.address){
      setCompanyAddress(company_info?.data?.address);
    }
    if(company_info?.data?.evidences)
    {
      checkInvestigationSource(company_info?.data?.evidences);
    }
  }, [company_info]);
  const [companyLocations, setCompanyLocations] = useState([]);
  const [companyDetails, setCompanyDetails] = useState(null);

  const checkInvestigationSource = (evidences) => {
    // check if any of the evidences have the source as "Investigation"
    const hasInvestigationSource = evidences.some(evidence => evidence.information_source === "Investigation");
    if (hasInvestigationSource) {
        setSourceInvestigation(true);
    }
  }

  const fetchLocations = async () => {
        try {
            const response = await axiosRequest.get(`${API_BASE}/loadCompanyLocations/?company=${company}`);
            setCompanyLocations(response.data?.locations);
            setCompanyDetails(response?.data?.company)
        }
        catch (error) {
            console.log(error)
        }
    }
    const fetch_company_info = async () => {
      try {
        const response = await axiosRequest.get(`${API_BASE}/getAllCompanyInfo/?search=${company}`);
        setCompanyInfo(response)
      } catch (error) {
        console.log(error)
        if(error?.response?.status === 401) {
            setShowLoginComponent(true);
        }
      }
      setLoading(false)
    }
  return (
    <>
      {loading && (
        <>
          <Grid container wrap="nowrap">
            {Array.from(new Array(2)).map((item, index) => (
              <Box
                key={index}
                sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}
              >
                <Skeleton variant="rectangular" width={"90%"} height={320} />
              </Box>
            ))}
          </Grid>
          <Grid container wrap="nowrap">
            {Array.from(new Array(2)).map((item, index) => (
              <Box
                key={index}
                sx={{ width: "100%", marginRight: 0.5, my: 5, marginLeft: 4 }}
              >
                <Skeleton variant="rectangular" width={"90%"} height={320} />
              </Box>
            ))}
          </Grid>
        </>
      )}
      {!loading && (
        <div className="about-component-container">
          <div style={{ marginTop: "1rem" }}>
            <DashboardSummaryComponent shipmentCount={shipmentCount.toLocaleString()} locCount={companyLocations.length.toLocaleString()} firstYear={minYear} endYear={maxYear} />
          </div>
          {source_investigation && (
            <div className="risk-indicator-container ">
                <p>The potential risk indicator for this entity has been added by the Sheffield Hallam University Forced Labor Lab</p>
            </div>
          )}
          {/* <CompanyLocations company={company} companyLocations={companyLocations} companyDetails={companyDetails}/> */}
          {
              showLoginComponent && (
                  <RedactLoginComponent companyName={companyName} />
              )
          }
          {!showLoginComponent && 
            <div>
                {company_info?.data?.year_data && ( 
                    <div>
                        <YearBreakdownChart data={company_info?.data?.year_data} />
                    </div>
                )}
                <div className="about-charts-container">
                    {company_info?.data?.top_suppliers?.length > 0 && (
                      <div className="about-chart-container">
                        <TopSupplierCustomerChart  company_info={company_info?.data} />
                      </div>
                    )}
                    {company_info?.data?.top_acquired_products?.length > 0 && (
                      <div className="about-chart-container">
                        <ProductCharts company_info={company_info} />
                      </div>
                    )}
                    {company_info?.data?.top_buying_countries?.length > 0 && (
                      <div className="about-chart-container">
                        <CountryCharts company_info={company_info} />
                      </div>
                    )}
                </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default DashboardComponent;