import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';

import { Grid, TextField, Button, Divider } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";

import { axiosRequest } from "../../services/utils/axios";
import { API_BASE } from "../../constants";

import "./login.css";

const VerifyUser = () => {

  const { email: encodedEmail } = useParams();
  const navigate = useNavigate();

  const [loginError, setLoginError] = useState(false);
  const [loginInput, setLoginInput] = useState({username: ''});
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    console.log(encodedEmail,"skskk")
    if (encodedEmail) {
      const decodedEmail = decodeURIComponent(encodedEmail);
      setLoginInput({
        ...loginInput,
        'username': encodedEmail,
      })
      console.log(loginInput)
      console.log('Decoded Email:', decodedEmail);
    } else {
      console.log('No email provided in the URL');
    }
  }, [encodedEmail]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoginLoading(true);
    const { username } = loginInput;
    const { otp } = loginInput;

    axiosRequest.post(`${API_BASE}/validate-otp`, {"userId": username, "otp": otp})
      .then((response) => {
        setLoginError(false);
        navigate('/login')
      })
      .catch((error) => {
        setLoginError(error.response.data || 'Unknown error occurred');
      });
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const newValue = event.target.value;
    setLoginInput({
      ...loginInput,
      [name]: newValue,
    });
  };

  const setRegister = (event) => {
    navigate("/register");
  };

  const handleSendOTP = () => {
    const { username } = loginInput;
    if (username) {
      // Send request to /send-otp with user_id as email
      axiosRequest
        .post(`${API_BASE}/send-otp`, {userId:username})
        .then((response) => {
          // Handle successful response if needed
            if(response.status === 200) {
                alert("OTP Sent");
            }
            setLoginError(false);
        })
        .catch((error) => {
          // Handle error if needed
          setLoginError(error.response.data || 'Unknown error occurred');
        });
    } else {
      // Handle case when username is not provided
    }
  };

  return (
    <div className="login-window">

      <Link to="/" className="unstyled-link">
        <div className="login-header" >
          <img
            className="login-page-logo"
            src={require('./logo/ST_OneLine_RGB.png')}
            alt="our logo"
          />
          <h3 className="login-page-logo-tagline">Open supply chain intelligence for a just world</h3>
        </div>
      </Link>

      <div style={{ textAlign: 'left', marginBottom: '1.5rem', color: '#0062b2' }}>
        <h4 >User Verification</h4>
      </div>

      <form onSubmit={handleSubmit}>
        <Grid container alignItems="center" direction="column" spacing={3}>
         <Grid item xs={12} className="wd-login-item">
            <Grid container spacing={2} alignItems="center">
              {/* Email Input */}
              <Grid item xs={10}>
                <label htmlFor="verify_email" style={{margin: 0}}>Email</label>
                <TextField
                  id="verify_email"
                  fullWidth
                  required
                  aria-label="username text box"
                  name="username"
                  type="text"
                  // label="Email"
                  value={loginInput['username']}
                  onChange={handleInput}
                />
              </Grid>
              {/* Send OTP Button */}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendOTP}
                >
                  Send OTP
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className="wd-login-item">
            <label htmlFor="verify_otp" style={{margin: 0}}>OTP</label>
            <TextField
              id="verify_otp"
              required
              fullWidth
              aria-label="otp"
              name="otp"
              // label="OTP"
              type="otp"
              onChange={handleInput}
            />
          </Grid>

          {
            loginError && (
              <p style={{color: "red", marginTop: "15px"}}>{loginError.data}</p>
            )
          }

          <Grid item xs={12} className="login-full-width">
            <Button
              className="wd-submit-btn-login"
              variant="contained"
              aria-label="submit button"
              onClick={handleSubmit}
            >
              Verify
            </Button>
          </Grid>
        </Grid>
      </form>

      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mt-4">
        <p className="mb-0">Don't have an account?</p>
        <MDBBtn outline className="mx-2" color="danger" onClick={setRegister}>
          Register
        </MDBBtn>
      </div>

    </div>
  );
};

export default VerifyUser;
